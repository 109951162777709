import Diana from "../images/new-images/testimonial-images/Diana Rotondo.jpg"
import Dave from "../images/new-images/testimonial-images/DavePlekkenpol.jpg"
import Derek from "../images/new-images/testimonial-images/Derek Hutchinson.jpg"
import Jane from "../images/new-images/testimonial-images/Jane Baum.jpg"
import Jessica from "../images/new-images/testimonial-images/Jessica_Donley.jpg"
import Jim from "../images/new-images/testimonial-images/Jim Fausel.jpg"
import Nick from "../images/new-images/testimonial-images/Nick Couture.jpg"
import thomas from "../images/new-images/testimonial-images/thomas.jpg"
import Bob from "../images/new-images/testimonial-images/bob-rizzuto.jpg"
import Grand from "../images/new-images/testimonial-images/grand-little.jpg"

import AinGroup from "../images/new-images/ain-group.png"
import Dexo from "../images/new-images/testimonials-dexko-global.png"
import KR from "../images/new-images/kr-wolfe-logo.png"
import Flyte from "../images/new-images/flyte-logo.png"
import Jacobs from "../images/new-images/testimonials-jacobs.png"
import PSG from "../images/new-images/psg-dover-logo.png"
import AF from "../images/new-images/afi-logo.png"
import Precedent from "../images/new-images/precedent-logo.png"

export const reviewData = [
  {
    title: "Nothing out of reach",
    review:
      "I moved our business applications to Quickbase in 2013 and have worked 3 other consultants, but when I found Quandary, they listened to my needs and learned my business which the previous companies did not do. They identified automations and solutions to increase our productivity and assist in managing a multitude of datasets which has increased our sales. There is nothing out of reach with my imagination and their ability to create processes and managment tools!",
    reviewer: "Diana Rotondo",
    position: "Executive Vice President",
    company: "AiN Group",
    companyImg: AinGroup,
    headShot: Diana,
    linkedLink: "https://www.linkedin.com/in/diana-rotondo-63b854b/",
  },
  {
    title: "Awesome Collaborative Partners",
    review:
      "We have been working with Quandary for a year or two now. They are great to work with and are very collaborative in working with you to develop tools that will work best for your organization. The development process from the customer perspective is always simple and easy. The team at Quandary is always very responsive to our needs, and goes above and beyond to ensure that we are satisfied customers.",
    reviewer: "Thomas Arlow",
    position: "Internal Audit / Internal Controls at DexKo Global",
    company: "Dexko",
    companyImg: Dexo,
    headShot: thomas,
    linkedLink: "https://www.linkedin.com/in/tom-arlow-7a2b969/",
  },
  {
    title: "A Great Business Partner!",
    review:
      "Just want to share that Quandary is an outstanding business partner. They have been instrumental in the design of our Quickbase app. Response time is fast. Their staff has taken the time to learn not only about our business but how we do business as well, resulting in a functional app. If you need a company to help you develop your Quickbase account, Quandary is the company to call.",
    reviewer: "Dave Plekkenpol",
    position: "Director Of Internal Operations at KR Wolfe Inc.",
    company: "KR Wolfe Inc.",
    companyImg: KR,
    headShot: Dave,
    linkedLink: "https://www.linkedin.com/in/daveplekkenpol/",
  },

  {
    title: "Caring, Competent and Communicative",
    review:
      "After an exhaustive search to find the right Quickbase Partner to enhance and upgrade our existing App, we came across the team at Quandary. Within the first few minutes of our discovery call, Kevin and his team completely understood our needs, developed a strategy for success, and delivered on-time, within budget and provided above and beyond service, with add-on's that we didn't know were possible after speaking with other providers. I cannot recommend the team at Quandary enough. Their in-depth understanding of our business process, their patience with our limited understanding of technology, and ultimately their implementation of a fantastic product -- communicating every step of the way -- has been a 'win' for our business. We are looking forward to years of partnership with this caring, competent and communicative team from Quandary.",
    reviewer: "Jim Fausel",
    position:
      "President @ FLYTEgroup. Flight Operations Hotel Booking Management.",
    company: "FLYTEgroup",
    companyImg: Flyte,
    headShot: Jim,
    linkedLink: "https://www.linkedin.com/in/jimfausel/?originalSubdomain=th",
  },
  // {
  //   title: "Very Happy",
  //   review:
  //     "Quandary has been an integral partner of our non-profit, JB Box. In a weeks time they built a user friendly, automated, and scalable application for us to manage all aspects of our business. What we once managed on spreadsheets, we now utilize Quickbase. Quandary was efficient, professional, and fairly priced. We knew our pricing up front and there were no surprises along the way. I would highly recommend their services. In addition to their Quickbase support, they also created our logo, branding, and new website! They have set up our non-profit for success and we couldn't have done it without them!",
  //   reviewer: "Jessica Donley",
  //   position: "Head of Product @ Fueled",
  //   company: "JB Box",
  //   headShot: Jessica,
  //   linkedLink: "https://www.linkedin.com/in/jessica-donley/",
  // },

  {
    title: "Efficient, Timely Updates, Superior Support",
    review:
      "I have been working with Quandary Consulting Group and Quickbase for almost 2 years. Quickbase provided a way to streamline a unique billing/payable process. Quandary designed a program specifically to transfer information between multiple systems. Tracking and reconciling information is efficient and simple. The staff reacts quickly to the changing processes by providing timely updates and superior support.",
    reviewer: "Jane Baum",
    position: "GE Program - Accounting Manager",
    company: "Jacobs",
    companyImg: Jacobs,
    headShot: Jane,
    linkedLink: "https://www.linkedin.com/in/jane-baum-27606b75/",
  },

  {
    title: "Key Member of Our Quickbase Development Team",
    review:
      "I have been working with Quandary sense Q1 of 2019, I first reached out to them inquiring about an interesting post I saw about how they had integrated Power BI and Quickbase. After a few short conversations they offered to come onsite and share this example along with other areas they could support. Their flexibility and ability to work outside the box is what solidified our choice to work with quandary. We have now engaged 3 different projects with them ranging from small tasks, troubleshooting calls, and even a full week onsite for a large project kick off. They have supported every initiative ranging from basic Quickbase development to professional programming/ customization. Along this process they have created a demo app to present to our executive staff, teach and coach our internal resources in both development and best practices. Their engagement, support and excitement has made them seem like an extension of our company to support and help develop our Quickbase platform and internal team. Even when requests were outside of their normal area of expertise, they were open about it and expressed willingness to expand their knowledge to help fully support our projects.",
    reviewer: "Derek Hutchinson",
    position:
      "Business Process and System Professional/ Leader/ Continuous Improvement",
    company: "PSG Dover",
    companyImg: PSG,
    headShot: Derek,
    linkedLink: "https://www.linkedin.com/in/derekhutchinson/",
  },
  // {
  //   title: "Thank you Quandary!",
  //   review:
  //     "My team has been with several partners over the years and Quandary stands out above the rest. They really understand our business and focus our Quickbase initiatives and applications truly on what we need. Their technical abilities are outstanding and can accomplish any request we throw at them whether through Quickbase or utilizing integration tools. They are communicative, professional, and total experts. As part of our team, they even help us manage and put on our annual conference. We couldn't do it without them. Thank you for everything you do for us Quandary!",
  //   reviewer: "Nick Couture",
  //   position: "Marketing Manager",
  //   company: "AiN Group",
  //   headShot: Nick,
  //   linkedLink: "https://www.linkedin.com/in/nick-couture-50a472117/",
  // },
  {
    title: "Much appreciated",
    review:
      "We were relying on excel spreadsheets to manage everything, and there’s no way we could have grown to where we are now without the custom application along with multiple integrations that Quandary built for us. We’re thrilled with how it’s turned out.",
    reviewer: "Bob Rizzuto",
    position: "Co-Owner",
    company: "Advanced Fiber",
    companyImg: AF,
    headShot: Bob,
    linkedLink: "https://www.linkedin.com/in/bob-rizzuto-b7076135/",
  },
  {
    title: "Thank You So Much.",
    review:
      "Working with experts isn’t always easy. They tend to think their way is the best way. Quandary presents solutions in a way that they can be discussed and well thought out so that the right decision can be made.",
    reviewer: "Grant Little",
    position: "CEO",
    company: "Precedent",
    companyImg: Precedent,
    headShot: Grand,
    linkedLink: "https://www.linkedin.com/in/grantlittle1/",
  },
]
